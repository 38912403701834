import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  constructor() {}
  get apiUrl(): string {
    // return 'https://auram-dev.setside.app';
    return 'https://auram.setside.app';
  }

  get mediaBaseUrl(): string {
    // return environment.production ? 'https://sid-web-storage.s3.ap-south-1.amazonaws.com/' : 'https://sid-web-storage.s3.ap-south-1.amazonaws.com/';
    return environment.production ? 'https://pub-d7fc7e0204644a99b00264cba5e82690.r2.dev/' : 'https://pub-d7fc7e0204644a99b00264cba5e82690.r2.dev/';
  }
}
