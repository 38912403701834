export const etAdminMenu = [
  {
    key: 'categories',
    title: 'Categories',
    route: '/main/categories',
    icon: 'question-circle',
    children: [],
  },
  {
    key: 'metal-price',
    title: 'Metal Price',
    route: '/main/metal-price',
    icon: 'dollar-circle',
    children: [],
  },
  {
    key: 'items',
    title: 'Item',
    route: '/main/item',
    icon: 'question-circle',
    children: [],
  },
  {
    key: 'collection',
    title: 'Collection',
    route: '/main/collection',
    icon: 'question-circle',
    children: [],
  },
  {
    key: 'discounts',
    title: 'Discount',
    route: '/main/discounts',
    icon: 'percentage',
    children: [],
  },
  {
    key: 'order',
    title: 'Order',
    route: '/main/order',
    icon: 'question-circle',
    children: [],
  },
  {
    key: 'newsletter',
    title: 'Newsletter',
    route: '/main/newsletter',
    icon: 'user',
    children: [],
  }

];
